import {
  CCarousel,
  CCarouselItem,
  CCol,
  CImage,
  CRow,
} from "@coreui/react-pro";
import Layout from "./Layout";
import banner1 from "../../../assets/img/banner/1.jpg";
import banner2 from "../../../assets/img/banner/2.jpg";
import banner3 from "../../../assets/img/banner/3.jpg";
import banner4 from "../../../assets/img/banner/4.jpg";

import { useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { useEffect, useState } from "react";
import logo from "../../../assets/img/mobile_logo.png";
import axios from "../../../shared/config/axios-interceptor";
import { IGameType } from "@/shared/model/gameType.model";

import game1 from "../../../assets/img/game/new/1.png";
import game2 from "../../../assets/img/game/new/2.png";
import game3 from "../../../assets/img/game/new/3.png";
import game4 from "../../../assets/img/game/new/4.png";
import game5 from "../../../assets/img/game/new/5.png";
import game6 from "../../../assets/img/game/new/6.png";

import image11 from "../../../assets/img/11.png";
import image22 from "../../../assets/img/22.png";
import image33 from "../../../assets/img/33.png";
import image44 from "../../../assets/img/44.png";
import Marquee from "react-fast-marquee";

import { NavLink } from "react-router-dom";

const HomeWap = () => {
  const { user } = useSelector((state: RootState) => state.authentication);

  const [tab, setTab] = useState(0);
  const [gameType, setGameType] = useState<IGameType[]>([]);

  const getGame = async () => {
    try {
      const response = await axios.get(
        "/game-type?limit=100&status=ACTIVE&sortBy=order&sortOrder=ASC&statusGame=ACTIVE"
      );
      setGameType(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getGame();
  }, []);

  const game = [
    {
      name: "Wynn",
      image: game1,
      id: 3,
    },
    {
      name: "Nhanh Ba",
      image: game2,
      id: 8,
    },
    {
      name: "Sự phân lẽ",
      image: game3,
      id: 11,
    },
    {
      name: "Hai phút",
      image: game4,
      id: 10,
    },
    {
      name: "Lưu Tinh Game",
      image: game5,
      id: 24,
    },
    {
      name: "3D",
      image: game6,
      id: 23,
    },
    // {
    //   // name: "5 out of 11",
    //   image: game7,
    //   id: 26

    // },
  ];

  return (
    <div className="home-wap">
      <Layout>
        <div className="home_top justify-content-center w-100">
          <div className="home_top-left">
            <div className="home_top-logo soc ">
              <img height={40} src={logo} alt="logo" />
            </div>
          </div>
        </div>

        <div className="main">
          <div className="banner-slide">
            <CCarousel controls interval={2000}>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner1} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner2} alt="slide 2" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner3} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner4} alt="slide 4" />
              </CCarouselItem>
            </CCarousel>
          </div>

          <div className="box-info p-2">
            <div className="d-flex gap-2 pb-1 align-items-center">
              <img
                width={20}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAdCAYAAADLnm6HAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASTSURBVHgB5Vc9bFtVFD7nXhsTWglngTLZRWJgwpXYcVgYGBxLqFJEUewMQArIZWFgqJMRFlokFP6q14qWDAicDmW1uyPFmRgYbH7EhBQPJbSO3z2cc+99f8VOk3ZAVU8cv/fuz/n5znfPeQZ41AXhAeWPS9caStELCPriM82lIRxRcnCf8vtXm1VUECBSWZ4JQ7m8D0eUIzsw2Ngso84FBOYlIIOKPQAi1AhFuA85tAODoFOc7E3OEWCLDRc5eYTyxRfFieRxmrX3n05QpnC/qwBGBZ1fwHpzFM2pwxj/+dPvG+EeDTjaNpsqCnGIo2bjcsNPSOoANt1mw8D5IkWVfRh303Pxtp8++nFRI9YQQlDagGLXGFZAbcpIpqo0h4oMuSJQSCRXAIOar2jHIDjReH1llhO7mxvluYLqst4S27iYXzxr+YLbbYa2MNdVmiqsjI3wB8UBMcIQsVFJsxiUca1iRzjqeK0EGJxYThzYvRZUQN0ezS+tDqOxW53PKnnIbStl2PfJQr7+Xk+Fjx/7hHGoODQpAZLAZhb8lx1gTyTnyRI/iZDJ/1/fBos8tq2w0N0Ngpicx+vv9HnpBbtFq7ZclSGq2QFESJcFG7Zojp2KSUaxWVHg12HKeX0Henzp83859wS00s7lobAuAPGOKt34uiQoOlLBdBKLYxEUYE3ZT+IB/LeazTeZ5aGvCRS20ijYE0B406F2p8HZIKtNYnBRR7YoBjZGAWMk0LLfAuT2330M599o9nigxxHMF46Fy+k5ovA6yQYDFWUjtKZYkTWUpoHxhi0K7uiRP/5M/cgx4oKEKW7E0Src8jypZCYey/etTTIVlaCIlMEyyqy/F2OIHiUxnCbsjJZiKPzVe1ZKj+fH4UgQ4KNUVl6/fGOc60inCzu+dxGTRcRWQu8QzqqCRltDMENEtXXAU4qSxTG90PEiGXXmbRFEm0fn1HQjKrTkM1Om3AYaqYT/sRJysIluQRs8MhK1r77+L43YtF6gSNccSXAnPT7h4+l6iRrIMaQIDkdGF5WzaK1TdA+WB862v7nH+wRVZSeX+K2MY1zyxYYhs6N4wU6EiWN5EpM/begwdlOpnFKqOGWrKEgpvrTG82VeP5w7vXIz4xbSoujmCr7FPYTWkyl7JAEy4LqaQI6kyZjLAILnSLob7n4T8MuKPi+LQjNeSBsfdzYarL/ESodYe/e6evHDV7a4vzRZzTCp/VHKkVxa7DFwVPXlCf0pcNy5KxMT6LPtHqA5l25G1AmK7Hdb6gY/rvlQE9n++EZF5/DJnNcirysYhie5Fbe5W9q6jVw7lZQM6ZDSCbUokS5Jl59unGnCAbL3w+cXcgpavG+Qq519VsYyb0SnPni1P2Wf5O/yLxudNkPa9jXR9q4IAUQguIf8/d0Xa7y6xdGP9glfjsYP9UYk8txqfX2MY/baXJFnokwjPvA03Op8WeGqd96SKFTNuXqSlkM7IPL86tLw5Nunm5z1U1znh5S8ERwox+tv9k1IK5y4auG1tzJHEuEB5Df+TaCZVMyDkkbTfGr5zBX4P+TPq1dL8LDKv0Z6IgldBIt7AAAAAElFTkSuQmCC"
              />
              <Marquee>
                <div style={{ fontSize: 13, gap: 50 }} className="d-flex">
                  <span style={{ color: "#0e90d2", fontSize: 13 }}>
                    - Để kỷ niệm 10 năm thành lập công ty, quyết định tri ân
                    người dùng cũ và mới, công ty hiện triển khai hoạt động phần
                    thưởng khi nạp tiền và đăng ký người dùng mới để nhận phần
                    thưởng bao lì xì đỏ $10. Hãy tham khảo dịch vụ khách hàng
                    trực tuyến " SỰ KIỆN NHẬN LÌ XÌ " để nhận thưởng.
                  </span>
                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    Các hoạt động nạp tiền nhận phần thưởng:
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    - Tích lũy nạp đủ 100.000.000VND (4081 đô la Mỹ) quý khách
                    hàng sẽ nhận được phần thưởng là được cộng thêm
                    10.000.000VND (408 đô la Mỹ).
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    - Tích lũy nạp đủ 300.000.000VND (12244 đô la Mỹ) quý khách
                    hàng sẽ nhận được phần thưởng là được cộng thêm
                    35.000.000VND (1428 đô la Mỹ).
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    - Tích lũy nạp đủ 500.000.000VND (20408 đô la Mỹ) quý khách
                    hàng sẽ nhận được phần thưởng là được cộng thêm
                    60.000.000VND (2449 đô la Mỹ).
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    - Tích lũy nạp đủ 1.000.000.000VND (40816 đô la Mỹ) quý
                    khách hàng sẽ nhận được phần thưởng là được cộng thêm
                    150.000.000VND (6122 đô la Mỹ).
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    Tất cả các hoạt động phần thưởng bạn đều có thể rút ra.
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    Thời gian hoạt động được áp dụng từ 00:00 ngày 01/02/2025
                    đến 00:00 ngày 01/03/2025!
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    - Đặc biệt lưu ý mỗi tài khoản chỉ được tham gia 1 lần trong
                    mỗi giai đoạn nhận thưởng của hoạt động nạp tiền.
                  </span>

                  <span
                    style={{ color: "#0e90d2", fontSize: 13, paddingLeft: 60 }}
                  >
                    - Khoản tiền nạp tối thiểu là 2.450.000VND (100 đô la Mỹ)
                  </span>
                </div>
              </Marquee>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <NavLink
              to={"/message"}
              className="text-center w-100 text-decoration-none text-dark"
            >
              <img width={45} src={image11} />
              <div style={{ fontSize: 14 }} className="fw-bold">
                Nạp tiền
              </div>
            </NavLink>
            <NavLink
              to={"/withdraw"}
              className="text-center w-100 text-decoration-none text-dark"
            >
              <img width={45} src={image22} />
              <div style={{ fontSize: 14 }} className="fw-bold">
                Rút tiền
              </div>
            </NavLink>
            <NavLink
              to={"/message"}
              className="text-center w-100 text-decoration-none text-dark"
            >
              <img width={45} src={image33} />
              <div style={{ fontSize: 14 }} className="fw-bold">
                CSKH
              </div>
            </NavLink>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <img width={100} src={image44} />
          </div>

          <div className="box-games d-flex p-0 mt-2">
            <div className="box-list-game w-100 p-0">
              {game.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    className="grid-item text-decoration-none"
                    to={`/game/${item.id}`}
                  >
                    <img
                      src={item.image}
                      style={{ width: "auto", height: 70, borderRadius: "50%" }}
                    />
                    <div className="mt-2" style={{ fontSize: 12 }}>
                      {item.name}
                    </div>
                  </NavLink>
                );
              })}
            </div>

            {/* <div className="box-game">
              <div
                className="container-box-game"
                style={{ paddingBottom: 200 }}
              >
                {gameType.map((item: IGameType, index) => {
                  return (
                    (tab === 0 || tab === item.id) && (
                      <div className="content1 mb-4" key={index}>
                        <div className="row g-0">
                          {item?.game.map((it, idx) => {
                            return (
                              <div key={idx} className="col-6">
                                <NavLink to={`/game/${it.id}`}>
                                  <CImage
                                    style={{ width: '100%' }}
                                    src={
                                      it.imageMobile ? it.imageMobile : game1
                                    }
                                  />
                                </NavLink>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
            </div> */}
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default HomeWap;
