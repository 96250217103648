import { NavLink } from "react-router-dom";
import home from "../../../assets/img/bottom/1.png";
import homeActive from "../../../assets/img/bottom/active/1.png";

import uuDai from "../../../assets/img/bottom/2.png";
import uuDaiActive from "../../../assets/img/bottom/active/2.png";

import cskh from "../../../assets/img/bottom/4.png";

import caNhan from "../../../assets/img/bottom/3.png";
import caNhanActive from "../../../assets/img/bottom/active/3.png";

const MenuBottom = () => {
  const listMenu = [
    { name: "Sảnh xổ số", icon: <i className="fa-solid fa-house"></i>, path: "/" },
    { name: "Đặt cược", icon: <i className="fa-solid fa-clock-rotate-left"></i>, path: "/history-order" },
    { name: "CSKH", icon: <i className="fa-solid fa-comment-dots"></i>, path: "/message" },
    { name: "Tôi", icon: <i className="fa-solid fa-user"></i>, path: "/account" },
  ]
  return (
    <div className="menu-bottom-wap d-flex justify-content-between align-items-center">
      {
        listMenu.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className="text-center w-100 d-flex flex-column  align-items-center"
          >
            {item.icon}
            <span>{item.name}</span>
          </NavLink>
        ))
      }
      {/* <NavLink
        to={"/"}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <i className="fa-solid fa-house"></i>
        <span>Sảnh xổ số</span>
      </NavLink>
      <NavLink
        to={"/history-order"}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img className="iat" width={21} src={uuDai} />
        <img className="at" width={21} src={uuDaiActive} />
        <span>Đặt cược</span>
      </NavLink>
      <NavLink
        to={"/message"}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={22} height={22} src={cskh} />
        <span>CSKH</span>
      </NavLink>
      <NavLink
        to={"/account"}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img className="iat" width={22} src={caNhan} />
        <img className="at" width={22} src={caNhanActive} />
        <span>Cá nhân</span>
      </NavLink> */}
    </div>
  );
};

export default MenuBottom;
